define("discourse/plugins/hosted-site/discourse/components/modal/admin-send-receipt", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "discourse/lib/ajax", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _ajax, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title="Send Receipt"
    @closeModal={{@closeModal}}
    class="hosted-send-receipt"
  >
    <:body>
      <p>
        Do you want your last receipt to be emailed to the current billing email
        address?
        <pre>{{@model.billingEmailAddress}}</pre>
      </p>
    </:body>
  
    <:footer>
      <DButton
        @translatedLabel="Yes"
        @action={{this.sendReceipt}}
        class="btn-primary"
      />
  
      <DButton
        @translatedLabel="Cancel"
        @action={{@closeModal}}
        class="btn-flat"
      />
    </:footer>
  </DModal>
  */
  {
    "id": "ihGJl120",
    "block": "[[[8,[39,0],[[24,0,\"hosted-send-receipt\"]],[[\"@title\",\"@closeModal\"],[\"Send Receipt\",[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,2],[12],[1,\"\\n      Do you want your last receipt to be emailed to the current billing email\\n      address?\\n      \"],[10,\"pre\"],[12],[1,[30,2,[\"billingEmailAddress\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,1],[[24,0,\"btn-primary\"]],[[\"@translatedLabel\",\"@action\"],[\"Yes\",[30,0,[\"sendReceipt\"]]]],null],[1,\"\\n\\n    \"],[8,[39,1],[[24,0,\"btn-flat\"]],[[\"@translatedLabel\",\"@action\"],[\"Cancel\",[30,1]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"d-button\"]]",
    "moduleName": "discourse/plugins/hosted-site/discourse/components/modal/admin-send-receipt.hbs",
    "isStrictMode": false
  });
  let AdminSendReceipt = _exports.default = (_class = class AdminSendReceipt extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "dialog", _descriptor, this);
    }
    async sendReceipt() {
      try {
        await (0, _ajax.ajax)("/admin/hosted_account/send_receipt.json");
        this.args.closeModal();
        this.dialog.alert("OK an email will be sent to you shortly. If you don't receive it, send an email to team@discourse.org.");
      } catch {
        this.args.closeModal();
        this.dialog.alert("There was an error. Please try again later, or send an email to team@discourse.org.");
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "sendReceipt", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sendReceipt"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdminSendReceipt);
});