define("discourse/plugins/hosted-site/discourse/components/hosted-plugins-info", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/plugins/hosted-site/discourse/components/modal/show-business-plugins", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _component, _object, _computed, _service, _showBusinessPlugins, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if standardTier}}
    <br />
    <button
      class="btn btn-primary"
      type="button"
      {{action "showBusinessPlugins"}}
    >
      Need more plugins?
    </button>
  {{/if}}
  */
  {
    "id": "e1SK+l4c",
    "block": "[[[41,[30,0,[\"standardTier\"]],[[[1,\"  \"],[10,\"br\"],[12],[13],[1,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[24,4,\"button\"],[4,[38,1],[[30,0],\"showBusinessPlugins\"],null],[12],[1,\"\\n    Need more plugins?\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `standardTier` property path was used in the `discourse/plugins/hosted-site/discourse/components/hosted-plugins-info.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.standardTier}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"action\"]]",
    "moduleName": "discourse/plugins/hosted-site/discourse/components/hosted-plugins-info.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
  let HostedPluginInfo = _exports.default = (_dec = (0, _computed.equal)("site.hosting_tier", "standard"), (_class = class HostedPluginInfo extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "modal", _descriptor, this);
      _initializerDefineProperty(this, "standardTier", _descriptor2, this);
    }
    showBusinessPlugins() {
      this.modal.show(_showBusinessPlugins.default);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "standardTier", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showBusinessPlugins", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showBusinessPlugins"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, HostedPluginInfo);
});