define("discourse/plugins/hosted-site/discourse/components/modal/admin-read-only-info", ["exports", "@ember/component", "@ember/object", "discourse/plugins/hosted-site/lib/downgrade", "@ember/template-factory"], function (_exports, _component, _object, _downgrade, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal @title="Read Only" @closeModal={{@closeModal}} class="read-only-info">
    <:body>
      <div class="read-only-warning">
        <div class="warning-icon">
          {{replace-emoji ":warning:"}}
        </div>
        <span class="warning-text">
          Are you absolutely sure? If you proceed, you won't be able to access
          this dashboard to revert this change. In the future, when you want to
          leave the read-only plan, contact us at team@discourse.org
        </span>
      </div>
  
      <div class="read-only-action">
        <DButton
          @translatedLabel="Switch to read-only"
          @action={{this.switchReadOnly}}
          class="btn-primary btn-danger"
        />
      </div>
    </:body>
  </DModal>
  */
  {
    "id": "Kik0cXIs",
    "block": "[[[8,[39,0],[[24,0,\"read-only-info\"]],[[\"@title\",\"@closeModal\"],[\"Read Only\",[30,1]]],[[\"body\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"read-only-warning\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"warning-icon\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\":warning:\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[14,0,\"warning-text\"],[12],[1,\"\\n        Are you absolutely sure? If you proceed, you won't be able to access\\n        this dashboard to revert this change. In the future, when you want to\\n        leave the read-only plan, contact us at team@discourse.org\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"read-only-action\"],[12],[1,\"\\n      \"],[8,[39,2],[[24,0,\"btn-primary btn-danger\"]],[[\"@translatedLabel\",\"@action\"],[\"Switch to read-only\",[30,0,[\"switchReadOnly\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"replace-emoji\",\"d-button\"]]",
    "moduleName": "discourse/plugins/hosted-site/discourse/components/modal/admin-read-only-info.hbs",
    "isStrictMode": false
  });
  let AdminReadOnlyInfo = _exports.default = (_class = class AdminReadOnlyInfo extends _component.default {
    switchReadOnly() {
      this.closeModal();
      (0, _downgrade.downgrade)(this.model.downgrade);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "switchReadOnly", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "switchReadOnly"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdminReadOnlyInfo);
});