define("discourse/plugins/hosted-site/discourse/components/modal/admin-upgrade-plan", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "discourse/lib/ajax", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _ajax, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal class="hosted-upgrade-modal">
    <:body>
      {{#if @model.suggested_upgrade_message}}
        <section class="upgrade-plan-details">
          <p>
            {{@model.suggested_upgrade_message}}
          </p>
          {{#if this.isTierUpgrade}}
            <p>
              You’ll also be able to do more with Discourse!
            </p>
            <p>
              Learn more about the
              <a
                href={{this.pluginsUrl}}
                target="_blank"
                rel="noopener noreferrer"
              >plugins</a>
              and
              <a
                href={{this.featuresUrl}}
                target="_blank"
                rel="noopener noreferrer"
              >features</a>
              available on the
              {{@model.suggestedTier}}
              plan.
            </p>
          {{/if}}
        </section>
        <p>
          Do you want to proceed?
        </p>
      {{else}}
        <p>
          Are you sure you want to upgrade?
        </p>
      {{/if}}
    </:body>
  
    <:footer>
      <DButton
        @translatedLabel="Yes"
        @action={{this.performUpgrade}}
        class="btn-primary"
      />
  
      <DButton @translatedLabel="No" @action={{@closeModal}} />
    </:footer>
  </DModal>
  */
  {
    "id": "xFUNXYFY",
    "block": "[[[8,[39,0],[[24,0,\"hosted-upgrade-modal\"]],null,[[\"body\",\"footer\"],[[[[1,\"\\n\"],[41,[30,1,[\"suggested_upgrade_message\"]],[[[1,\"      \"],[10,\"section\"],[14,0,\"upgrade-plan-details\"],[12],[1,\"\\n        \"],[10,2],[12],[1,\"\\n          \"],[1,[30,1,[\"suggested_upgrade_message\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"],[41,[30,0,[\"isTierUpgrade\"]],[[[1,\"          \"],[10,2],[12],[1,\"\\n            You’ll also be able to do more with Discourse!\\n          \"],[13],[1,\"\\n          \"],[10,2],[12],[1,\"\\n            Learn more about the\\n            \"],[10,3],[15,6,[30,0,[\"pluginsUrl\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"plugins\"],[13],[1,\"\\n            and\\n            \"],[10,3],[15,6,[30,0,[\"featuresUrl\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"features\"],[13],[1,\"\\n            available on the\\n            \"],[1,[30,1,[\"suggestedTier\"]]],[1,\"\\n            plan.\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n      \"],[10,2],[12],[1,\"\\n        Do you want to proceed?\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,2],[12],[1,\"\\n        Are you sure you want to upgrade?\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"]],[]],[[[1,\"\\n    \"],[8,[39,2],[[24,0,\"btn-primary\"]],[[\"@translatedLabel\",\"@action\"],[\"Yes\",[30,0,[\"performUpgrade\"]]]],null],[1,\"\\n\\n    \"],[8,[39,2],null,[[\"@translatedLabel\",\"@action\"],[\"No\",[30,2]]],null],[1,\"\\n  \"]],[]]]]]],[\"@model\",\"@closeModal\"],false,[\"d-modal\",\"if\",\"d-button\"]]",
    "moduleName": "discourse/plugins/hosted-site/discourse/components/modal/admin-upgrade-plan.hbs",
    "isStrictMode": false
  });
  let AdminUpgradePlan = _exports.default = (_class = class AdminUpgradePlan extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "dialog", _descriptor, this);
    }
    get isTierUpgrade() {
      return this.args.model.currentTier !== this.args.model.suggestedTier;
    }
    get pluginsUrl() {
      return `https://www.discourse.org/plugins#${this.args.model.suggestedTier.toLowerCase()}`;
    }
    get featuresUrl() {
      return `https://www.discourse.org/pricing#compare-plans`;
    }
    async performUpgrade() {
      try {
        const data = await (0, _ajax.ajax)("/admin/hosted_plan.json", {
          type: "PUT",
          data: {
            plan_id: this.args.model.plan_id,
            add_on_ids: this.args.model.add_on_ids
          }
        });
        this.args.closeModal();
        if (data.success) {
          this.dialog.alert(data.message || "Your account has been successfully upgraded.");
        } else {
          this.dialog.alert(`Your upgrade could not be completed. ${data.message}`);
        }
        this.args.model.onComplete();
      } catch {
        this.args.closeModal();
        this.dialog.alert("There was an error submitting your upgrade request. Please send an email to team@discourse.org so we can look into it.");
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dialog", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "performUpgrade", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "performUpgrade"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdminUpgradePlan);
});