define("discourse/plugins/hosted-site/discourse/components/modal/admin-po-number", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/lib/ajax", "I18n", "@ember/template-factory"], function (_exports, _component, _object, _computed, _service, _ajax, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title={{i18n "hosted_site.po_number.title"}}
    @closeModal={{@closeModal}}
    class="hosted-invoice-details"
  >
    <:body>
      <p>{{i18n "hosted_site.po_number.description"}}</p>
  
      {{#if this.errorMessage}}
        <p class="error">
          {{d-icon "times"}}
          {{this.errorMessage}}
        </p>
      {{/if}}
  
      <div class="address-and-tax-sections">
        <div class="address-section">
          <h3 class="subtitle">
            {{i18n "hosted_site.po_number.purchase_order_heading"}}
          </h3>
  
          <Input
            @type="text"
            @value={{this.invoiceDetails.purchase_order_number}}
            placeholder={{i18n "hosted_site.po_number.purchase_order_number"}}
            autocorrect="off"
            autocapitalize="off"
          />
          <InvoiceDetailError
            @validationErrors={{this.validationErrors}}
            @fieldName="purchase_order_number"
          />
        </div>
      </div>
    </:body>
  
    <:footer>
      <DButton
        @label="hosted_site.po_number.save"
        @action={{this.save}}
        @disabled={{this.saving}}
        class="btn-primary"
      />
  
      <DButton
        @translatedLabel="Cancel"
        @action={{@closeModal}}
        class="btn-flat"
      />
    </:footer>
  </DModal>
  */
  {
    "id": "V6kFjORC",
    "block": "[[[8,[39,0],[[24,0,\"hosted-invoice-details\"]],[[\"@title\",\"@closeModal\"],[[28,[37,1],[\"hosted_site.po_number.title\"],null],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,2],[12],[1,[28,[35,1],[\"hosted_site.po_number.description\"],null]],[13],[1,\"\\n\\n\"],[41,[30,0,[\"errorMessage\"]],[[[1,\"      \"],[10,2],[14,0,\"error\"],[12],[1,\"\\n        \"],[1,[28,[35,3],[\"times\"],null]],[1,\"\\n        \"],[1,[30,0,[\"errorMessage\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,0],[14,0,\"address-and-tax-sections\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"address-section\"],[12],[1,\"\\n        \"],[10,\"h3\"],[14,0,\"subtitle\"],[12],[1,\"\\n          \"],[1,[28,[35,1],[\"hosted_site.po_number.purchase_order_heading\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[8,[39,4],[[16,\"placeholder\",[28,[37,1],[\"hosted_site.po_number.purchase_order_number\"],null]],[24,\"autocorrect\",\"off\"],[24,\"autocapitalize\",\"off\"]],[[\"@type\",\"@value\"],[\"text\",[30,0,[\"invoiceDetails\",\"purchase_order_number\"]]]],null],[1,\"\\n        \"],[8,[39,5],null,[[\"@validationErrors\",\"@fieldName\"],[[30,0,[\"validationErrors\"]],\"purchase_order_number\"]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,6],[[24,0,\"btn-primary\"]],[[\"@label\",\"@action\",\"@disabled\"],[\"hosted_site.po_number.save\",[30,0,[\"save\"]],[30,0,[\"saving\"]]]],null],[1,\"\\n\\n    \"],[8,[39,6],[[24,0,\"btn-flat\"]],[[\"@translatedLabel\",\"@action\"],[\"Cancel\",[30,1]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"if\",\"d-icon\",\"input\",\"invoice-detail-error\",\"d-button\"]]",
    "moduleName": "discourse/plugins/hosted-site/discourse/components/modal/admin-po-number.hbs",
    "isStrictMode": false
  });
  let AdminPoNumber = _exports.default = (_dec = (0, _computed.alias)("model.invoiceDetails"), (_class = class AdminPoNumber extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "dialog", _descriptor, this);
      _defineProperty(this, "saving", false);
      _defineProperty(this, "errorMessage", void 0);
      _defineProperty(this, "validationErrors", void 0);
      _initializerDefineProperty(this, "invoiceDetails", _descriptor2, this);
    }
    async save() {
      this.setProperties({
        errorMessage: null,
        validationErrors: null,
        saving: true
      });
      let data = {};
      if (this.invoiceDetails.purchase_order_number) {
        data.purchase_order_number = this.invoiceDetails.purchase_order_number;
      }
      try {
        const response = await (0, _ajax.ajax)("/admin/invoice_details.json", {
          type: "PUT",
          data
        });
        if (response.success) {
          this.closeModal();
          this.dialog.alert(_I18n.default.t("hosted_site.po_number.success"));
          this.model.onComplete();
        } else {
          this.set("errorMessage", response.message);
          if (response.errors) {
            this.set("validationErrors", response.errors);
          }
        }
      } catch {
        this.closeModal();
        this.dialog.alert("There was an error updating your details. Please send an email to team@discourse.org so we can look into it and update your account info.");
      } finally {
        this.set("saving", false);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "invoiceDetails", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdminPoNumber);
});