define("discourse/plugins/hosted-site/discourse/components/modal/admin-restricted-plan-info", ["exports", "@ember/component", "@ember/object", "@ember/service", "discourse-common/utils/decorators", "discourse/plugins/hosted-site/lib/downgrade", "discourse/plugins/hosted-site/discourse/components/modal/admin-read-only-info", "@ember/template-factory"], function (_exports, _component, _object, _service, _decorators, _downgrade, _adminReadOnlyInfo, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title="Downgrade Service"
    @closeModal={{@closeModal}}
    class="restricted-plan-info"
  >
    <:body>
      <div class="staff-only-info">
        <h4>Staff-only plan</h4>
        <ul>
          <li>Staff members can login and create new content</li>
          <li>Regular members can view the site, but cannot login</li>
        </ul>
      </div>
  
      <div class="staff-only-action">
        <p>
          <b>{{this.staffOnlyCost}}</b>
        </p>
        <DButton
          @translatedLabel="Switch to staff-only"
          @action={{this.switchStaffOnly}}
        />
      </div>
  
      <div class="read-only-info">
        <h4>Read-only plan</h4>
        <ul>
          <li>No users can login</li>
          <li>No new content can be created</li>
          <li>All content is kept online</li>
        </ul>
      </div>
  
      <div class="read-only-action">
        <p>
          <b>{{this.readOnlyCost}}</b>
        </p>
        <DButton
          @translatedLabel="Switch to read-only"
          @action={{this.switchReadOnly}}
        />
      </div>
    </:body>
  </DModal>
  */
  {
    "id": "4We63i96",
    "block": "[[[8,[39,0],[[24,0,\"restricted-plan-info\"]],[[\"@title\",\"@closeModal\"],[\"Downgrade Service\",[30,1]]],[[\"body\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"staff-only-info\"],[12],[1,\"\\n      \"],[10,\"h4\"],[12],[1,\"Staff-only plan\"],[13],[1,\"\\n      \"],[10,\"ul\"],[12],[1,\"\\n        \"],[10,\"li\"],[12],[1,\"Staff members can login and create new content\"],[13],[1,\"\\n        \"],[10,\"li\"],[12],[1,\"Regular members can view the site, but cannot login\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"staff-only-action\"],[12],[1,\"\\n      \"],[10,2],[12],[1,\"\\n        \"],[10,\"b\"],[12],[1,[30,0,[\"staffOnlyCost\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,1],null,[[\"@translatedLabel\",\"@action\"],[\"Switch to staff-only\",[30,0,[\"switchStaffOnly\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"read-only-info\"],[12],[1,\"\\n      \"],[10,\"h4\"],[12],[1,\"Read-only plan\"],[13],[1,\"\\n      \"],[10,\"ul\"],[12],[1,\"\\n        \"],[10,\"li\"],[12],[1,\"No users can login\"],[13],[1,\"\\n        \"],[10,\"li\"],[12],[1,\"No new content can be created\"],[13],[1,\"\\n        \"],[10,\"li\"],[12],[1,\"All content is kept online\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"read-only-action\"],[12],[1,\"\\n      \"],[10,2],[12],[1,\"\\n        \"],[10,\"b\"],[12],[1,[30,0,[\"readOnlyCost\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,1],null,[[\"@translatedLabel\",\"@action\"],[\"Switch to read-only\",[30,0,[\"switchReadOnly\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"d-button\"]]",
    "moduleName": "discourse/plugins/hosted-site/discourse/components/modal/admin-restricted-plan-info.hbs",
    "isStrictMode": false
  });
  let AdminRestrictedPlanInfo = _exports.default = (_dec = (0, _decorators.default)("model.readOnlyCost"), _dec2 = (0, _decorators.default)("model.staffOnlyCost"), (_class = class AdminRestrictedPlanInfo extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "modal", _descriptor, this);
    }
    readOnlyCost(readOnlyCost) {
      return readOnlyCost;
    }
    staffOnlyCost(staffOnlyCost) {
      return staffOnlyCost;
    }
    switchStaffOnly() {
      const downgrade = this.model.cancellationAlternatives.staff_only;
      this.closeModal();
      (0, _downgrade.downgradeWithConfirmation)(downgrade);
    }
    switchReadOnly() {
      const downgrade = this.model.cancellationAlternatives.read_only;
      this.modal.show(_adminReadOnlyInfo.default, {
        model: {
          downgrade
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "readOnlyCost", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "readOnlyCost"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "staffOnlyCost", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "staffOnlyCost"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "switchStaffOnly", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "switchStaffOnly"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "switchReadOnly", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "switchReadOnly"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdminRestrictedPlanInfo);
});