define("discourse/plugins/hosted-site/discourse/components/modal/admin-contact-email", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/utilities", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _ajax, _utilities, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title="Change Contact Email Address"
    @closeModal={{@closeModal}}
    class="hosted-contact-email"
  >
    <:body>
      <p>
        Set the email address where we can reach you about your Discourse hosting
        service.
      </p>
      <p>
        <TextField
          @value={{this.contactEmail}}
          @placeholderKey="topic.invite_reply.email_placeholder"
          @id="hosted-contact-email-input"
          @autocorrect="off"
          @autocapitalize="off"
          @type="email"
        />
        <br />
        <InputTip @validation={{this.emailValidation}} />
      </p>
    </:body>
  
    <:footer>
      <DButton
        @translatedLabel="Save"
        @action={{this.save}}
        @disabled={{this.invalidEmail}}
        class="btn-primary"
      />
  
      <DButton
        @translatedLabel="Cancel"
        @action={{@closeModal}}
        class="btn-flat"
      />
    </:footer>
  </DModal>
  */
  {
    "id": "bBfCH+s0",
    "block": "[[[8,[39,0],[[24,0,\"hosted-contact-email\"]],[[\"@title\",\"@closeModal\"],[\"Change Contact Email Address\",[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,2],[12],[1,\"\\n      Set the email address where we can reach you about your Discourse hosting\\n      service.\\n    \"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@value\",\"@placeholderKey\",\"@id\",\"@autocorrect\",\"@autocapitalize\",\"@type\"],[[30,0,[\"contactEmail\"]],\"topic.invite_reply.email_placeholder\",\"hosted-contact-email-input\",\"off\",\"off\",\"email\"]],null],[1,\"\\n      \"],[10,\"br\"],[12],[13],[1,\"\\n      \"],[8,[39,2],null,[[\"@validation\"],[[30,0,[\"emailValidation\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn-primary\"]],[[\"@translatedLabel\",\"@action\",\"@disabled\"],[\"Save\",[30,0,[\"save\"]],[30,0,[\"invalidEmail\"]]]],null],[1,\"\\n\\n    \"],[8,[39,3],[[24,0,\"btn-flat\"]],[[\"@translatedLabel\",\"@action\"],[\"Cancel\",[30,1]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"text-field\",\"input-tip\",\"d-button\"]]",
    "moduleName": "discourse/plugins/hosted-site/discourse/components/modal/admin-contact-email.hbs",
    "isStrictMode": false
  });
  let AdminContactEmail = _exports.default = (_class = class AdminContactEmail extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "dialog", _descriptor, this);
      _initializerDefineProperty(this, "contactEmail", _descriptor2, this);
    }
    get invalidEmail() {
      return !(0, _utilities.emailValid)(this.contactEmail);
    }
    get emailValidation() {
      if (this.invalidEmail && this.contactEmail) {
        return _object.default.create({
          failed: true,
          reason: _discourseI18n.default.t("user.email.invalid")
        });
      }
    }
    async save() {
      try {
        await (0, _ajax.ajax)("/admin/hosted_account/update_contact_email.json", {
          type: "PUT",
          data: {
            email: this.contactEmail
          }
        });
        this.args.closeModal();
        this.dialog.alert("Your contact email address has been updated.");
        this.args.model.onComplete();
      } catch {
        this.args.closeModal();
        this.dialog.alert("There was an error updating your contact email address. Please send an email to team@discourse.org so we can look into it and update your account info.");
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "contactEmail", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdminContactEmail);
});